import { JarvisAuthProvider } from '@jarvis/web-http'

type ManageDeviceProps = {
  country?: string
  language?: string
  authProvider: JarvisAuthProvider
}
let localprops: ManageDeviceProps = {
  language: 'en',
  country: 'US',
  authProvider: null
}

export const setProps = (props) => {
  localprops = props
}

export const getProps = (): ManageDeviceProps => {
  return localprops
}

// export const breadcrumbVal = (deviceName, defaultPath) => {
//   const overview = [
//     {
//       key: 'home',
//       text: 'Home',
//       url: `/`,
//       onClick: function (e) {
//         window.history.pushState('object or string', 'Title', `/`)
//         e.preventDefault()
//       }
//     },
//     {
//       key: 'solutions',
//       text: 'Solutions',
//       url: `/solutions`
//     },
//     {
//       key: 'submenu-solutions-security',
//       text: 'HP Secure Fleet Manager',
//       onClick: function (e) {
//         e.preventDefault()
//       }
//     }
//   ]

//   const devices = [
//     {
//       key: 'home',
//       text: 'Home',
//       url: `/`,
//       onClick: function (e) {
//         window.history.pushState('object or string', 'Title', `/`)
//         e.preventDefault()
//       }
//     },
//     {
//       key: 'solutions',
//       text: 'Solutions',
//       url: `/solutions`,
//       onClick: function (e) {
//         window.history.pushState('object or string', 'Title', `/solutions`)
//         e.preventDefault()
//       }
//     },
//     {
//       key: 'submenu-solutions-security',
//       text: 'HP Secure Fleet Manager',
//       url: '/solutions/security'
//     },
//     {
//       key: 'devices',
//       text: 'Devices',
//       onClick: function (e) {
//         e.preventDefault()
//       }
//     }
//   ]

//   const deviceDetails = [
//     {
//       key: 'home',
//       text: 'Home',
//       url: `/`,
//       onClick: function (e) {
//         window.history.pushState('object or string', 'Title', `/`)
//         e.preventDefault()
//       }
//     },
//     {
//       key: 'solutions',
//       text: 'Solutions',
//       url: `/solutions`,
//       onClick: function (e) {
//         window.history.pushState('object or string', 'Title', `/solutions`)
//         e.preventDefault()
//       }
//     },
//     {
//       key: 'submenu-solutions-security',
//       text: 'HP Secure Fleet Manager',
//       url: `${defaultPath}`,
//       onClick: function (e) {
//         window.history.pushState('object or string', 'Title', `${defaultPath}`)
//         e.preventDefault()
//       }
//     },
//     {
//       key: 'devices',
//       text: 'Devices',
//       url: '/solutions/security/devices'
//     },
//     {
//       key: 'submenu-solutions-security-device-details',
//       text: deviceName,
//       onClick: function (e) {
//         e.preventDefault()
//       }
//     }
//   ]

//   return { overview, devices, deviceDetails }
// }
